<template>
  <div class="container">
    <NavBar />
    <bindPhoneOne v-if="showOne" :phone="userInfo.tel" @close="closeOne" />
    <!-- 验证当前手机号 -->
    <bindPhoneTwo
      v-if="showTwo"
      :phone="userInfo.tel"
      @success="successTwo"
    ></bindPhoneTwo>
    <!-- 换绑手机号 -->
    <bindPhoneThree v-if="showThree" @success="back" />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import bindPhoneOne from './components/bindPhoneOne.vue'
import bindPhoneTwo from './components/bindPhoneTwo.vue'
import bindPhoneThree from './components/bindPhoneThree.vue'
export default {
  components: {
    NavBar,
    bindPhoneOne,
    bindPhoneTwo,
    bindPhoneThree
  },
  data() {
    return {
      showOne: true,
      showTwo: false,
      showThree: false
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    }
  },
  methods: {
    closeOne() {
      this.showOne = false
      this.showTwo = true
    },
    successTwo() {
      this.showTwo = false
      this.showThree = true
    },
    back() {
      this.$store.dispatch('getUserInfo')
      this.$router.go(-1)
    }
  }
}
</script>

<style></style>
