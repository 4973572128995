<template>
  <div class="box">
    <van-notice-bar left-icon="volume-o" text="请验证新手机号" />
    <div class="login-form">
      <van-field
        v-model="phone"
        autocomplete="off"
        type="digit"
        placeholder="输入手机号"
      />
      <van-field
        class="fieldCode"
        v-model="code"
        type="digit"
        autocomplete="off"
        placeholder="短信验证码"
      >
        <template #button>
          <span class="sendCode" @click="getCode">{{ codeTile }}</span>
        </template>
      </van-field>
      <van-button
        class="btn"
        round
        :color="disableColor"
        :loading="loading"
        block
        @click="submit"
        >保存</van-button
      >
    </div>
  </div>
</template>

<script>
import { mobileCode, changeBindMobile } from '@/api/user.js'
import { phoneReg } from '@/utils/reg.js'
export default {
  data() {
    return {
      phone: '',
      code: '',
      codeTile: '发送验证码',
      isAbled: false,
      loading: false
    }
  },
  computed: {
    disableColor() {
      if (this.phone === '' || this.code === '') {
        return '#413C33'
      } else {
        return '#CB7E0E'
      }
    }
  },
  methods: {
    // 手机号验证
    validator() {
      if (!this.phone) {
        this.$toast('请输入手机号')
        return false
      } else if (phoneReg.test(this.phone)) {
        return true
      } else {
        this.$toast('手机号格式不正确')
        return false
      }
    },
    async getCode() {
      if (this.isAbled) return false
      if (this.validator()) {
        this.isAbled = true
        const params = {
          mobile: this.phone
        }
        const res = await mobileCode(params)
        if (res.status === 200) {
          this.validateBtn()
        } else {
          this.$toast(res.msg)
        }
      }
    },
    validateBtn() {
      // 倒计时
      let time = 60
      const timer = setInterval(() => {
        if (time === 0) {
          clearInterval(timer)
          this.isAbled = false
          this.codeTile = '发送验证码'
        } else {
          this.isAbled = true
          this.codeTile = time + 's'
          time--
        }
      }, 1000)
    },
    submit() {
      this.loading = true
      if (this.validator() && this.code) {
        changeBindMobile({
          mobile: this.phone,
          smsCode: this.code
        })
          .then((res) => {
            this.loading = false
            if (res.status === 200) {
              this.$emit('success')
            }
          })
          .catch(() => {
            this.loading = false
          })
      } else if (!this.code) {
        this.$toast('请输入验证码')
        this.loading = false
        return false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  box-sizing: border-box;
  padding: 20px 0;
  .login-form {
    margin-top: 20px;
    .sendCode {
      color: @text-color-1;
    }
    .btn {
      width: 313px;
      height: 42px;
      margin: auto;
      margin-top: 50px;
    }
  }
}
</style>
