<template>
  <div class="box">
    <img class="box-img" src="../../../assets/images/bind_phone.png" alt="" />
    <div class="text">已绑定手机号</div>
    <div class="phone">{{ phone }}</div>
    <van-button class="btn" round color="#CB7E0E" block @click="submit"
      >更换手机号</van-button
    >
  </div>
</template>

<script>
export default {
  props: {
    phone: {
      type: String,
      default: ''
    }
  },
  methods: {
    submit() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  .box-img {
    width: 138px;
    height: 138px;
  }
  .text {
    margin-top: 30px;
    font-size: 13px;
    color: #aaaaaa;
  }
  .phone {
    margin-top: 13px;
    font-size: 15px;
    color: #ffffff;
  }
  .btn {
    margin-top: 250px;
  }
}
</style>
